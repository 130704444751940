import React from 'react'
//import PlayerAPI from '../api'
import { Link } from 'react-router-dom'

// The Player looks up the player using the number parsed from
// the URL's pathname. If no player is found with the given
// number, then a "player not found" message is displayed.
const ideasCategory = (props) => {
  return (
    <div>
      <h1>{props.match.params.category} from ideas category page</h1>
      <Link to='/Ideas'>Ideas  page</Link>
    </div>
  )
}

export default ideasCategory