import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ProductCarousel from './components/slider'
import "./home.scss";
import nextArrow from  "../../Icons/nextArrow.svg"
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },

  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabcontent: {
    display: "none",
    padding: "6px 12px",
    border: "1px solid #ccc",
    borderTop: "none",
  },
}));

function openCity(evt, cityName) {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(cityName).style.display = "block";
  evt.currentTarget.className += " active";
  var bg;
  switch (cityName) {
    case "Ideas":
      bg = "linear-gradient(to right, #19a9d0 70%, #e2e2e2 70%)";
      break;
    case "Products":
      bg = "linear-gradient(to right, #007898 60%, #e2e2e2 60%)";
      break;
    case "Services":
      bg = "linear-gradient(to right, #2d9fbe 65%, #e2e2e2 65%)";
  }
  {
    var x = document.getElementsByClassName("Intro");
    x[0].style.background = bg;
  }
}

export default function Home(props) {
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={3}
        className="Intro" id="intro"
      >
        <Grid item xs={12}  >

          <div
            id="Products"
            style={{ display: "block" }}
            className="tabcontent"
          >
            <Grid container>
              <Grid item xs={5}><h1 className="products-h1">Find the best <span class="products-h1-span">Interior products</span> for your Home and Work</h1>
                <h3 class="products-p">Products from <b className="products-p-b">3000+</b> suppliers to take care of your needs</h3>
              </Grid>
              <Grid item xs={7}  >
                <div className="products-div">x<ProductCarousel/></div>
              </Grid>
            </Grid></div>
          <div id="Services" style={{ display: "none" }} className="tabcontent">
            <Grid container>
              <Grid item xs={5}><h1 className="products-h1">Find the best <span class="products-h1-span">Interior products</span> for your Home and Work</h1>
                <h3 class="products-p">Products from <b className="products-p-b">3000+</b> suppliers to take care of your needs</h3>
              </Grid>
              <Grid item xs={7}  >
                <div className="products-div">x</div>
              </Grid>
            </Grid>
          </div>

          <div id="Ideas" style={{ display: "none" }} className="tabcontent">
            <Grid container>
              <Grid item xs={5}><h1 className="products-h1">Find the best <span class="products-h1-span">Interior products</span> for your Home and Work</h1>
                <h3 class="products-p">Products from <b className="products-p-b">3000+</b> suppliers to take care of your needs</h3>
              </Grid>
              <Grid item xs={7}  >
                <div className="products-div">x</div>
              </Grid>
            </Grid>
          </div>

        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justify="left"
        alignItems="left"
        item
        xs={3}
      >
        <Box spacing={1} className="bottom">
          <Paper spacing={1}
            xs={3}
            className={classes.paper}
          >
            <div className="tab ">
              <Button
                color="Primary"
                variant="contained"
                onClick={(e) => {
                  openCity(e, "Products");
                }}><div className="svg-box">
                <object data={nextArrow} type="image/svg+xml"></object>
                </div>Products
                
              </Button>
              <Button
                color="Primary"
                variant="contained"
                onClick={(e) => {
                  openCity(e, "Services");
                }}
              >
                Services
              </Button>
              <Button
                color="Primary"
                variant="contained"
                onClick={(e) => {
                  openCity(e, "Ideas");
                }}
              >
                Ideas
              </Button>
            </div>
          </Paper></Box>
      </Grid>
      <br/><br/><br/>
      <Link to="/Ideas">Ideas</Link>
    </div>
  );
}
