import React, { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom'
import DOMPurify from 'dompurify'; 
import curl from "../../common";
export default function Idea(props) {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ideas, setIdeas] = useState([]);
  useEffect(() => {
    fetch(curl + "api/ideas/getideaold?articletitle=" + props.match.params.ideaname.replace(/-/g, ' '))
    .then(res => res.json())
    .then(
      (result) => {
        setIsLoaded(true);
        setIdeas(result);
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
  }, [props.match.params.ideaname])

  return (
    <div>
    <div dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(ideas.description)} } />
      <h1>this is idea.js {props.match.params.ideaname.replace(/-/g, ' ')}</h1>
      <Link to='/idea/Mumbai-Development-Plan-2034'>Mumbai Development Plan</Link><br/>
      <Link to='/idea/Home-decor-ideas-for-bedroom'>Home-decor-ideas-for-bedroom</Link><br/>
      <Link to='/idea/15-common-mistakes-in-the-house'>15-common-mistakes-in-the-house</Link><br/>
      <a href='/idea/15-common-mistakes-in-the-house'>15-common-mistakes-in-the-house</a>
    </div>
  )
}
