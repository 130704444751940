import React from 'react'
//import PlayerAPI from '../api'
import Idea from '../pages/idea/idea'
import {Route, Switch, Redirect } from 'react-router-dom';
// The Player looks up the player using the number parsed from
// the URL's pathname. If no player is found with the given
// number, then a "player not found" message is displayed.
const IdeaRoute = (props) => {
  return (
    <idea>
    <Switch>
      <Route  path='/Idea/:ideaname' component={Idea}/>
      <Redirect path ='/' to='/Ideas'/>
    </Switch>
  </idea>
  )
}

export default IdeaRoute
