import React, { useState } from "react";
import "./Card.scss";
import $ from 'jquery';
export default function Card() {

    function Hover()
    {
        $('#product-card').hover(function(){
            $(this).addClass('animate');		
         }, function(){
            $(this).removeClass('animate');			
    });	
    }
    function ShowBack(){
        $('#product-card').addClass('flip-10');
        setTimeout(function(){
            $('#product-card').removeClass('flip-10').addClass('flip90').find('div.shadow').show().fadeTo( 80 , 1, function(){
                $('#product-front, #product-front div.shadow').hide();			
            });
        }, 50);
        
        setTimeout(function(){
            $('#product-card').removeClass('flip90').addClass('flip190');
            $('#product-back').show().find('div.shadow').show().fadeTo( 90 , 0);
            setTimeout(function(){				
                $('#product-card').removeClass('flip190').addClass('flip180').find('div.shadow').hide();						
                setTimeout(function(){
                    $('#product-card').css('transition', '100ms ease-out');			
                    $('#cx, #cy').addClass('s1');
                    setTimeout(function(){$('#cx, #cy').addClass('s2');}, 100);
                    setTimeout(function(){$('#cx, #cy').addClass('s3');}, 200);					
                }, 100);
            }, 100);			
        }, 150);	
    }

    function ShowFront()
    {
        $('#product-card').removeClass('flip180').addClass('flip190');
        setTimeout(function(){
            $('#product-card').removeClass('flip190').addClass('flip90');
    
            $('#product-back div.shadow').css('opacity', 0).fadeTo( 100 , 1, function(){
                $('#product-back, #product-back div.shadow').hide();
                $('#product-front, #product-front div.shadow').show();
            });
        }, 50);
        
        setTimeout(function(){
            $('#product-card').removeClass('flip90').addClass('flip-10');
            $('#product-front div.shadow').show().fadeTo( 100 , 0);
            setTimeout(function(){						
                $('#product-front div.shadow').hide();
                $('#product-card').removeClass('flip-10').css('transition', '100ms ease-out');		
                $('#cx, #cy').removeClass('s1 s2 s3');			
            }, 100);			
        }, 150);	
    }

   
    const [hovered, setHovered] = useState(false);
    const toggleHover = () => setHovered(!hovered);

    const [showBack, setShowBack] = useState(false);
    const toggleShowBack = () => setShowBack(!showBack);

    
    const [showFront, setShowFront] = useState(false);
    const toggleShowFront = () => setShowFront(!showFront);
    return (
        <div>
            <div id="make-3D-space">
                <div id="product-card"  className={[(hovered ? 'animate' : ''), (showBack ? 'flip9dd0' : '' )]
  .filter(e => !!e)
  .join(' ')
}
                    onMouseEnter={toggleHover}
                    onMouseLeave={toggleHover}>
                    <div id="product-front">
                        <div class="shadow"></div>
                        <img className="card-image" src="https://images-na.ssl-images-amazon.com/images/I/51QpMWs3GOL.jpg" alt="" />
                        <div class="image_overlay"></div>
                        <div onClick={() => {ShowBack();{toggleShowBack()} }} id="view_details">View details</div>
                        <div class="stats">
                            <div class="stats-container">
                                <span class="product_price"></span>
                                <span class="product_name">Teak Dining Table For 4 People</span>
                                <p>Dining Tables</p>

                                <div class="product-options">
                                    <span>Sturdy dining table made up of gorgeous teak wood</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  id="product-back">
                    Back side
                        <div class="shadow"></div>
                        <div onClick={() => {ShowFront();{toggleShowBack()} }} id="flip-back">
                            <div id="cy"></div>
                            <div id="cx"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}