import React from 'react'
//import PlayerAPI from '../api'
import { Link } from 'react-router-dom'

import Card from "../../components/Products/Card"
// The Player looks up the player using the number parsed from
// the URL's pathname. If no player is found with the given
// number, then a "player not found" message is displayed.
const Category = (props) => {
  return (
    <div>
      <h1>{props.match.params.cat1}</h1>
      <h2>{props.match.params.cat2}</h2>
      <div style={{display:"inlineBlock"}}>
      <Card/><div>&nbsp </div>
      <Card/> <div>&nbsp </div><Card/>
      </div>
      <Link to='/Home'>Home</Link>
    </div>
  )
}

export default Category