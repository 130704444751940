import React from "react";
import "../css/navigation.css";
import SearchBar from "./SearchBar/SearchBar";
export default class Navigation extends React.Component {
  render() {
    return (
      <div>
        <div className="header-div">
      <header className="ap--header">
        <svg
          style={{position: "absolute", width: "0",height: "0",overflow: "hidden"}}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xlinkHref="http://www.w3.org/1999/xlink"
        >
          <defs>
            <symbol
              id="angle-down-solid"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
            >
              <path d="M56.293 16.003c1.761-1.645 4.553-1.645 6.314 0 1.857 1.734 1.857 4.623 0 6.357l-27.45 25.638c-1.761 1.645-4.553 1.645-6.314 0l-27.45-25.638c-0.899-0.839-1.393-1.963-1.393-3.178s0.494-2.339 1.393-3.178c1.761-1.645 4.553-1.645 6.314 0l24.293 22.689 24.293-22.689z"></path>
            </symbol>
          </defs>
        </svg>
        <div className="ap-o-header">
          <a className="ap-o-header-home" href="/">
            <img alt="logo"
              layout="responsive"
              src={process.env.PUBLIC_URL + "/II.png"}
              className="ii-header-home-logo"
            ></img>
            <div className="ii-header-home-title">InteriorsInfo</div>
          </a>
          <SearchBar options={[
          "Papaya",
          "Persimmon",
          "Paw Paw",
          "Prickly Pear",
          "Peach",
          "Pomegranate",
          "Pineapple"
        ]}  />
        </div>
        </header></div>
        <div className="ap-o-header-low">
          <nav className="ap-o-header-main">
            <div className="ap-o-header-main-item">
              <button className="ap-o-header-main-link ap-m-nav-link ">
                Products
                <div className="ap-a-ico ap-o-header-main-link-icon">
                  <svg>
                    <use
                      xlinkHref="#angle-down-solid"
                    ></use>
                  </svg>
                </div>
              </button>

              <ul className="ap-o-header-flyout">
                <li className="ap-o-header-flyout-primary-item">
                  <a
                    className="ap-o-header-flyout-primary-item-link websites"
                    href="/about/websites/"
                  >
                    <div className="ap-o-header-flyout-primary-item-link-icon websites">
                      <svg>
                        <use
                          xlinkHref="#Furniture"
                        ></use>
                      </svg>
                    </div>

                    <div className="ap-o-header-flyout-primary-item-link-info ap-o-header-flyout-primary-item-link-info-websites">
                      <div className="ap-o-header-flyout-item-title">Furniture</div>
                      <div className="ap-o-header-flyout-item-description">
                        Beautiful Range Of Furniture at your price
                      </div>
                    </div>
                  </a>
                </li>

                <li className="ap-o-header-flyout-primary-item">
                  <a
                    className="ap-o-header-flyout-primary-item-link stories"
                    href="/about/stories/"
                  >
                    <div className="ap-o-header-flyout-primary-item-link-icon stories">
                      <svg>
                        <use
                          xlinkHref="#Bath"
                        ></use>
                      </svg>
                    </div>

                    <div className="ap-o-header-flyout-primary-item-link-info ap-o-header-flyout-primary-item-link-info-stories">
                      <div className="ap-o-header-flyout-item-title">
                        Bath & Plumbing
                      </div>
                      <div className="ap-o-header-flyout-item-description">
                        Divine Bath Experience with excellent bathroom products
                      </div>
                    </div>
                  </a>
                </li>

                <li className="ap-o-header-flyout-primary-item">
                  <a
                    className="ap-o-header-flyout-primary-item-link ads"
                    href="/about/ads/"
                  >
                    <div className="ap-o-header-flyout-primary-item-link-icon ads">
                      <svg>
                        <use
                          xlinkHref="#amp-ads"
                        ></use>
                      </svg>
                    </div>

                    <div className="ap-o-header-flyout-primary-item-link-info ap-o-header-flyout-primary-item-link-info-ads">
                      <div className="ap-o-header-flyout-item-title">AMP Ads</div>
                      <div className="ap-o-header-flyout-item-description">
                        Super fast ads on the web
                      </div>
                    </div>
                  </a>
                </li>

                <li className="ap-o-header-flyout-primary-item">
                  <a
                    className="ap-o-header-flyout-primary-item-link email"
                    href="/about/email/"
                  >
                    <div className="ap-o-header-flyout-primary-item-link-icon email">
                      <svg>
                        <use
                          xlinkHref="#amp-email"
                        ></use>
                      </svg>
                    </div>

                    <div className="ap-o-header-flyout-primary-item-link-info ap-o-header-flyout-primary-item-link-info-email">
                      <div className="ap-o-header-flyout-item-title">AMP Email</div>
                      <div className="ap-o-header-flyout-item-description">
                        Next gen email
                      </div>
                    </div>
                  </a>
                </li>

                <li className="ap-o-header-flyout-item-secondary">
                  <a
                    className="ap-o-header-flyout-item-title secondary"
                    href="/about/how-amp-works/"
                  >
                    How AMP works
                  </a>
                </li>

                <li className="ap-o-header-flyout-item-secondary">
                  <a
                    className="ap-o-header-flyout-item-title secondary"
                    href="/about/mission-and-vision/"
                  >
                    Vision and Mission
                  </a>
                </li>

                <li className="ap-o-header-flyout-item-secondary">
                  <a
                    className="ap-o-header-flyout-item-title secondary"
                    href="/about/use-cases/"
                  >
                    Use cases
                  </a>
                </li>

                <li className="ap-o-header-flyout-item-secondary">
                  <a
                    className="ap-o-header-flyout-item-title secondary"
                    href="/success-stories/"
                  >
                    Success Stories
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}
