import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Card from "../../../components/Products/Card"
import "./slider.scss"
export default function ProductCarousel() {
    const renderSlides = () =>
        [1, 2, 3, 4, 5, 6, 7, 8].map(num => (
            <div><Card></Card>
                
            </div>
        ));
    const sliderSettings = {

        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        infinite: true,
        accessibility: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 300,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    return (
        <div className="App">
            <Slider {...sliderSettings} dots={true}>{renderSlides()}</Slider>
        </div>
    );
}