import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Main from "./routes/Main";
import AMP from "./routes/AMP";
import IdeasRoute from "./routes/Ideas";
import Idea from "./routes/Idea";
import home from './pages/home/home'
import { BrowserRouter } from "react-router-dom";
import ProductCarousel from './pages/home/components/slider'
class App extends Component {
  state = {
    data: null,
  };

  // componentDidMount() {
  //   // Call our fetch function below once the component mounts
  //   this.callBackendAPI()
  //     .then((res) => this.setState({ data: res.express }))
  //     .catch((err) => console.log(err));
  // }
  // Fetches our GET route from the Express server. (Note the route we are fetching matches the GET route from server.js
  // callBackendAPI = async () => {
  //   const response = await fetch("/express_backend");
  //   const body = await response.json();

  //   if (response.status !== 200) {
  //     throw Error(body.message);
  //   }
  //   return body;
  // };

  render() {
    return (
      <div>
      <BrowserRouter>
        <Switch>
          <Route path="/Idea" component={Idea} />
          <Route path="/Ideas(/)" component={IdeasRoute} />
          <Route path="/Amp" component={AMP} />
          <Route path="/" component={Main} />
          <Route exact path="/" component={home} />
          <Redirect to="/home" />
        </Switch>
      </BrowserRouter>
    </div>
    
    
    );
  }
}

export default App;
