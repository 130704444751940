import React, { useEffect, useRef, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import curl from "../../common";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#635ee7",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#464646e0",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
      color: "#464646e0",
      fontWeight:"600",
    },
    minWidth: "50px",
    
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "absolute",
    paddingTop: "3px",
    width: "100%",
    paddingRight: "36px"
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: "#fff",
    boxShadow: "0.5px 1px 4px 0px #007ddf5e",
    borderRadius: "15px",
  },
}));

function useOutsideAlerter(ref) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        var box = document.querySelector(".custom-tab");
        if (box) box.classList.add("js-is-hidden");
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function CustomizedTabs(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [ideas, setIdeas] = useState([]);
  const [autofill, setAutofill] = useState([]);
  useEffect(() => {fetch(curl + "api/home/GetProductSugesstiveNew?searchText="+props.ui+"&City="+props.city)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setProducts(result.products);
          setServices(result.services);
          setIdeas(result.ideas);
          setAutofill(result.autofill);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  },[props.ui])

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [visibility, setVisibility] = React.useState(true);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  if (!visibility) return null;
  return (
    <div ref={wrapperRef} className={classes.root}>
      <div className={classes.demo2}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab className={classes.button} label="All" {...a11yProps(0)} />
          <StyledTab label="Products" {...a11yProps(1)} />
          <StyledTab label="Services" {...a11yProps(2)} />
          <StyledTab label="Ideas" {...a11yProps(3)} />
        </StyledTabs>
        <TabPanel value={value} index={0}>
         <small>Search Results for <b> {props.ui}</b></small>
        {products.map(item => (
          <div style={{listStyle: "none",paddingBottom:'2px',textTransform:"lowercase"}} key={item.pid}>
          {item.name} <img style={{height: "25px"}} src={"https://interiorsinfo.com/ImageBox/thumbnail/" + item.photo}></img> 
          </div>
        ))}
        </TabPanel>
        <TabPanel value={value} index={1}>
        {services.map(item => (
          <div style={{listStyle: "none",paddingBottom:'2px',textTransform:"lowercase"}} key={item.pid}>
          {item.name} <img style={{height: "25px"}} src={"https://interiorsinfo.com/ImageBox/thumbnail/" + item.photo}></img> 
          </div>
        ))}
        </TabPanel>
        <TabPanel value={value} index={2}>
        {services.map(item => (
          <div style={{listStyle: "none",paddingBottom:'2px',textTransform:"lowercase"}} key={item.pid}>
          {item.name} {item.pid} <img style={{height: "25px"}} src={"https://interiorsinfo.com/ImageBox/thumbnail/" + item.photo}></img> 
          </div>
        ))}
        </TabPanel>
        <TabPanel value={value} index={3}>
        {ideas.map(item => (
          <div style={{listStyle: "none",paddingBottom:'2px',textTransform:"capitalize"}} key={item.pid}>
          <img style={{height: "50px"}} src={"https://interiorsinfo.com/ImageBox/thumbnail/" + item.photo}></img> {item.headline}  
          </div>
        ))}
        </TabPanel>
        <Typography className={classes.padding} />
      </div>
    </div>
  );
}
