import React from "react";
import ReactDOM from "react-dom";
import "./SearchBar.scss";
import CustomizedTabs from "./autocomplete"
import PropTypes from 'prop-types';


export default class SearchBar extends React.Component {

  static propTypes = {
    options: PropTypes.instanceOf(Array).isRequired
  };
  state = {
    activeOption: 0,
    filteredOptions: [],
    showOptions: false,
    userInput: ''
  };

  onChange = (e) => {
    console.log('onChanges');

    const { options } = this.props;
    const userInput = e.currentTarget.value;

    const filteredOptions = options.filter(
      (optionName) =>
        optionName.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    var b = document.querySelector(".custom-tab");
    if(b){
    if (b.classList.contains("js-is-hidden"))
    {
      b.classList.remove("js-is-hidden")
    }}
    this.setState({
      activeOption: 0,
      filteredOptions,
      showOptions: true,
      userInput: e.currentTarget.value
    });
  };

  onClick = (e) => {
    this.setState({
      activeOption: 0,
      filteredOptions: [],
      showOptions: false,
      userInput: e.currentTarget.innerText
    });
  };
  onKeyDown = (e) => {
    const { activeOption, filteredOptions } = this.state;

    if (e.keyCode === 13) {
      this.setState({
        activeOption: 0,
        showOptions: false,
        userInput: filteredOptions[activeOption]
      });
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      this.setState({ activeOption: activeOption - 1 });
    } else if (e.keyCode === 40) {
      if (activeOption === filteredOptions.length - 1) {
        console.log(activeOption);
        return;
      }
      this.setState({ activeOption: activeOption + 1 });
    }
  };
  render() {
    const {
      onChange,
      onClick,
      onKeyDown,

      state: { activeOption, filteredOptions, showOptions, userInput }
    } = this;
    let optionList;
    if (showOptions && userInput) {
      if (filteredOptions.length) {
        optionList = (
          <div className="custom-tab">
            <CustomizedTabs  ui={userInput} city="713" />
         </div>
        );
      } else {
        optionList = (
          <div className="no-options custom-tab">
            <CustomizedTabs className={'custom-tab'} ui={userInput} city="713"/>
          </div>
        );
      }
    }
    return (<div className="search">
    <input
      type="text"
      className="search-box" placeholder="Search products,services and ideas"
       onChange={onChange}
      onKeyDown={onKeyDown}
      value={userInput}
    />
    <input type="submit" value="" className="search-btn" />
    {optionList}
  </div>
    );
  }
}
