import React from 'react'
import { Switch, Route ,Redirect} from 'react-router-dom'
import category from '../pages/category/category'
import home from '../pages/home/home'
import ProductCarousel from '../pages/home/components/slider'
import Card from '../components/Products/Card'
import CategoryAll from '../pages/category/category'
//import Roster from './Roster'
//import Schedule from './Schedule'

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"
const Main = () => (
  <main>
    <Switch>
      <Route  path="/:cat1/:cat2" component={category}/>
      
      <Route  path="/Slider" component={ProductCarousel} />
      <Route  path="/Cat" component={CategoryAll} />
      <Route exact path="/" component={home} />
      <Route path="/ProductCard" component={Card}/>
      <Redirect to="home"></Redirect>

    </Switch>
  </main>
)

export default Main
