import React, { useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom'
import curl from "../../common";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';

export default function Ideas(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
    },
    image: {
      width: 128,
      height: 128,
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  }));
  
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ideas, setIdeas] = useState([]);
  useEffect(() => {
    fetch(curl + "api/ideas/getideaslist")
    .then(res => res.json())
    .then(
      (result) => {
        setIsLoaded(true);
        setIdeas(result);
      },
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
  }, [props.match.params.ideaname])

  return (
    <div>
    {ideas.map(item => (
      <Link to={'/idea/'+item.title.replace(/ /g, "-")}> <div style={{listStyle: "none",paddingBottom:'2px',textTransform:"lowercase"}} key={item.articleID}>
          {item.headline} <img style={{height: "25px"}} src={"https://interiorsinfo.com/ImageBox/thumbnail/" + item.articleLogo}></img> 
          </div></Link>
        ))}
      <Link to='/idea/Mumbai-Development-Plan-2034'>Mumbai Development Plan</Link><br/>
      <Link to='/idea/Home-decor-ideas-for-bedroom'>Home-decor-ideas-for-bedroom</Link><br/>
      <Link to='/idea/15-common-mistakes-in-the-house'>15-common-mistakes-in-the-house</Link><br/>
      <a href='/idea/15-common-mistakes-in-the-house'>15-common-mistakes-in-the-house</a>
    </div>
  )
}
